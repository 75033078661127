import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './abount.css'

const Abount = (props) => {
  return (
    <div className="abount-container">
      <Helmet>
        <title>abount - at helper</title>
        <meta property="og:title" content="abount - exported project" />
      </Helmet>
      <div className="abount-about">
        <div className="abount-header1">
          <img
            src="/external/header2657-yt0s.svg"
            alt="header2657"
            className="abount-header2"
          />
          <span className="abount-text10">Founder</span>
          <span className="abount-text11">Vivian Lee</span>
          <img
            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/7a86a003-b4c8-40c0-bc34-9cc002077856/37e64b1d-8df9-4788-98d2-9fc4d89c6785?org_if_sml=171030&amp;force_format=original"
            alt="Ellipse13078"
            className="abount-ellipse1"
          />
          <span className="abount-text12">
            <span>
              <span>
                <span>
                  We believe that observability distinguishes teams that deploy
                  models blindly from those dedicated to creating impactful
                  products.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
            <br></br>
            <br></br>
            <span>
              This commitment to excellence underscores the importance of
              automated test script generation, enabling teams to ensure
              quality, maintain control, and continuously improve their
              processes for better outcomes.
            </span>
          </span>
          <span className="abount-text23">
            Build AI that users can rely on with confidence.
          </span>
          <Link to="/" className="abount-text24">
            Contact us
          </Link>
          <Link to="/" className="abount-text25">
            Features
          </Link>
          <span className="abount-text26">About</span>
          <Link to="/" className="abount-navlink">
            <img
              src="/external/logo2669-l6u-200w.png"
              alt="logo2669"
              className="abount-logo1"
            />
          </Link>
        </div>
        <div className="abount-features">
          <img
            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/7a86a003-b4c8-40c0-bc34-9cc002077856/3dd182c4-3efe-4dd3-912c-ea435c7c811f?org_if_sml=1609768&amp;force_format=original"
            alt="background267"
            className="abount-background"
          />
          <span className="abount-text27">
            <span>
              <span>
                <span>
                  In today’s fast-paced tech landscape, ensuring the quality of
                  software products is more challenging than ever.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
            <br></br>
            <br></br>
            <span>
              When issues arise—whether due to bugs, performance failures, or
              security vulnerabilities—they often go unnoticed until it’s too
              late. If you have a product in production and things go wrong, you
              might be the last to find out. Meanwhile, your users are affected,
              which erodes trust and can lead to churn.
            </span>
            <br></br>
            <br></br>
            <span>That&apos;s why we&apos;re building AT Helper.</span>
            <br></br>
            <br></br>
            <span>
              Our goal is to simplify the process of generating automated test
              scripts through AI, making it easier for teams to verify that
              their software works as intended and to quickly identify and
              resolve any issues. With AT Helper, you can enhance your testing
              processes and ensure a reliable experience for your users.
            </span>
          </span>
        </div>
        <div className="abount-footer">
          <img
            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/7a86a003-b4c8-40c0-bc34-9cc002077856/821537a5-6ffb-4b04-836e-7e3bcd3ece13?org_if_sml=17468&amp;force_format=original"
            alt="logo265"
            className="abount-logo2"
          />
        </div>
      </div>
    </div>
  )
}

export default Abount
