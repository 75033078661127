import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>At Helper Home</title>
      </Helmet>
      <div className="home-home">
        <div className="home-footer">
          <img
            src="/external/logo1565-lk4n-200w.png"
            alt="logo1565"
            className="home-logo1"
          />
        </div>
        <div className="home-pagelink1">
          <span className="home-text10">Read more</span>
          <span className="home-text11">Maverick Ventures</span>
          <span className="home-text12">
            <span>
              “Non-technical stakeholders are more easily able to
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              plug themselves into prompt engineering and
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>monitoring.”</span>
          </span>
          <img
            src="/external/line11551-2pmh.svg"
            alt="Line11551"
            className="home-line11"
          />
          <span className="home-text18">
            <span>Seamless Collaboration</span>
            <br></br>
          </span>
          <span className="home-text21">
            <span>
              Empower tech and non-technical teams through a
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>collaborative and friendly UI.</span>
          </span>
        </div>
        <div className="home-pagelink2">
          <span className="home-text25">Read more</span>
          <span className="home-text26">IBM</span>
          <span className="home-text27">
            <span>“The global average cost of a data breach in 2023 was</span>
            <br></br>
            <span> USD 4.45 million, a 15% increase over 3 years.”</span>
          </span>
          <img
            src="/external/line11561-y5h7.svg"
            alt="Line11561"
            className="home-line12"
          />
          <span className="home-text31">Full visibility &amp; safeguards</span>
          <span className="home-text32">
            <span>
              Gain full visibility into your LLM application&apos;s behavior
            </span>
            <br></br>
            <span> with comprehensive test coverage.</span>
          </span>
        </div>
        <div className="home-features">
          <img
            src="/external/background1568-rfd-1500w.png"
            alt="background1568"
            id="feature_section"
            className="home-background"
          />
          <span className="home-text36">Features</span>
          <div className="home-docupload">
            <img
              src="/external/rectangle752398-5f1c-700h.png"
              alt="Rectangle752398"
              className="home-rectangle751"
            />
            <span className="home-text37">
              Experience seamless document feeding with fast upload speeds,
              automated file organization, and support for multiple formats.
            </span>
            <span className="home-text38">Quick Document Feeding</span>
            <img
              src="/external/doc2310-eumc-400h.png"
              alt="doc2310"
              className="home-doc"
            />
          </div>
          <div className="home-caserunner">
            <img
              src="/external/rectangle752386-sc6c-800h.png"
              alt="Rectangle752386"
              className="home-rectangle752"
            />
            <span className="home-text39">
              <span>
                Effortlessly run test scripts with our comprehensive suite of
                features. Experience fast execution speeds, detailed reporting,
                and real-time monitoring, all designed to enhance your testing
                workflow.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>
                With built-in error tracking, custom configuration options, and
                support for multiple environments, our platform ensures
                reliable, high-quality results every time.
              </span>
            </span>
            <span className="home-text44">Run Test Scripts Seamlessly</span>
            <img
              src="/external/runner2389-t98x-900w.png"
              alt="runner2389"
              className="home-runner"
            />
          </div>
          <div className="home-caseeditor">
            <span className="home-text45">
              <span>
                Enjoy seamless online script editing with rich features,
                including syntax highlighting, version control, and real-time
                collaboration.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>
                Designed for flexibility, our platform lets you streamline your
                workflow, easily customize scripts, and boost productivity with
                powerful, intuitive tools.
              </span>
            </span>
            <span className="home-text50">Effortless Script Editing</span>
            <img
              src="/external/editor2382-wao-900w.png"
              alt="editor2382"
              className="home-editor"
            />
          </div>
          <div className="home-caselist1">
            <img
              src="/external/rectangle752072-kdk-500w.png"
              alt="Rectangle752072"
              className="home-rectangle753"
            />
            <span className="home-text51">
              <span>
                Cover any real-world scenario and build a comprehensive test
                suite.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>
                Break-down test cases into granular test steps and detect
                regressions in your product.
              </span>
            </span>
            <span className="home-text56">End-to-end test management</span>
            <img
              src="/external/caselist2073-52iu-400h.png"
              alt="caselist2073"
              className="home-caselist2"
            />
          </div>
          <div className="home-contact">
            <div className="home-contactinfo">
              <div className="home-container2">
                <span className="home-text57">vivian.lee@athelper.online</span>
                <img
                  src="/external/email3383-iwbn.svg"
                  alt="email3383"
                  className="home-email1"
                />
              </div>
              <span className="home-text58">
                We&apos;re here to help you at anytime. Contact us by filling
                out the form and we&apos;ll get back to you ASAP.
              </span>
              <span className="home-text59">Get in touch</span>
            </div>
            <div id="contact_input" className="home-contactinput">
              <div>
                <span className="home-name-tag">Full Name:</span>
                <input
                  type="text"
                  className="home-name"
                />
              </div>
              <div>
                <span className="home-text63">Email:</span>
                <input
                  type="email"
                  className="home-email2"
                />
              </div>
              <div>
                <span className="home-text62">Phone:</span>
                <input
                  type="tel"
                  className="home-phone"
                />
              </div>
              <span className="home-text61">Message:</span>
              <textarea
                className="home-msg"
              />
              <div className="home-frame2">
                <button className="home-text60">Submit</button>
              </div>
            </div>
          </div>
          <span className="home-text65">A 360º view of your QA process</span>
        </div>
        <div className="home-header1">
          <img
            src="/external/header53-wxlf.svg"
            alt="header53"
            className="home-header2"
          />
          <span className="home-text66">
            Empower tech and non-technical teams to test your product and ship
            reliable products faster.
          </span>
          <span className="home-text67">
            AI-Powered Test Script Generation: Boost Efficiency, Ensure Quality!
          </span>
          <div className="home-emailbutton1">
            <input
              type="email"
              placeholder="Enter your email"
              className="home-emailbutton2"
            />
            <button className="home-button">
              <span className="home-text69">Join Waitting List</span>
            </button>
          </div>
          <a href="#contact_input" className="home-text70">
            Contact us
          </a>
          <a href="#feature_section" className="home-text71">
            Features
          </a>
          <Link to="/abount" className="home-text72">
            About
          </Link>
          <img
            src="/external/logo915-tn6-200w.png"
            alt="logo915"
            className="home-logo2"
          />
        </div>
      </div>
    </div>
  )
}

export default Home
